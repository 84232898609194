
























import { defineComponent } from '@vue/composition-api'
import { mapState } from 'vuex'
import { TrainingType } from '@/api/interfaces/trainingtype'

export default defineComponent({
  name: 'SelectSearchTrainingType',
  components: {
    SelectSearch: () => import('../m-select-search.vue'),
  },
  props: {
    value: {
      type: [Object, Array, String],
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    returnObject: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDetails: {
      type: String,
      required: false,
      default: '',
    },
    persistentHint: {
      type: Boolean,
      required: false,
      default: false,
    },
    classes: {
      type: String,
      required: false,
      default: '',
    },
    chips: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState('trainingType', {
      listTrainingTypes: 'list',
    }),
    listTrainingTypesFiltered (): Array<TrainingType> {
      if (Array.isArray(this.value)) {
        return this.listTrainingTypes.filter(
          (x: TrainingType) => x.isActive || (this.value as Array<TrainingType>).some((y: TrainingType) => y['@id'] === x['@id']),
        )
      }

      if (typeof this.value === 'string') {
        return this.listTrainingTypes.filter(
          (x: TrainingType) => x.isActive || x['@id'] === this.value,
        )
      }

      return this.listTrainingTypes.filter(
        (x: TrainingType) => x.isActive || (this.value && x['@id'] === (this.value as TrainingType)['@id']),
      )
    },
  },
  methods: {
    itemText (item: TrainingType) {
      return item.name
    },
    onInput (value: string | TrainingType | Array<TrainingType>) {
      if (typeof value === 'string') {
        const trainingType = (this.listTrainingTypesFiltered as Array<TrainingType>).find(
          x => x['@id'] === value,
        )
        this.$emit('input', trainingType)
        return
      }
      this.$emit('input', value)
    },
  },
})
